import { graphql, PageProps } from "gatsby";
import React from "react";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { Nav } from "../components/Nav";
import { SEO } from "../components/SEO";

const FeaturePage = (props: PageProps<{ data: Queries.WpFeaturePage }>) => {
	return (
		<>
			<Nav navGroup={props.data.data.page_meta!.navLink!} />
			<Hero hero={{ ...props.data.data.hero!, image: props.data.data.featuredImage!.node! }} enablePadding={false} />
			<section className="bg-light border-top py-3">
				<div className="container">
					<div dangerouslySetInnerHTML={{ __html: props.data.data.content! }} />
				</div>
			</section>
			<Footer navGroup={props.data.data.page_meta?.footer?.nav_links2!} />
		</>
	)
}
export default FeaturePage;

export const Head = (props: PageProps<{ data: Queries.WpFeaturePage }>) => {
	return (
		<SEO title={props.data.data.title!} description={props.data.data.page_meta?.description!.replace(/(<([^>]+)>)/gi, "")!} pathname={props.data.data.page_meta?.path!} />
	)
}

export const query = graphql`
query ($id: Int!) {
  data: wpFeaturePage(databaseId: {eq: $id}) {
    databaseId
    title
    content
    featuredImage {
      node {
        sourceUrl
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
    hero {
      title
      byline
      callToAction {
        text
        __typename
        linkType
        internalLink {
          ... on WpPost {
            id
            slug
          }
          ... on WpHomepage {
            id
            page_meta {
              path
            }
          }
        }
      }
    }
    page_meta {
      path
      description
      footer {
        ...LinkGroup
      }
      navLink {
        ...LinkGroup
      }
    }
  }
}
`;